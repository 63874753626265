import { useEffect, useState } from 'react'

import { useCameraFeed } from './useCameraFeed'

import { useDeviceFeedDetailsQuery } from '@/graphql/generated/hooks'
import { DeviceType } from '@/graphql/generated/schemas'

import {
  DeviceFeedIProps,
  IncidentPlayerError,
  VideoFeedEvent,
} from '../types/types'

export function useFindDeviceFeed(
  deviceId: string,
  onEvent?: (event: VideoFeedEvent) => void,
  isLiveStreamEnabled: boolean = true
): DeviceFeedIProps {
  const [availableCamerasIds, setAvailableCamerasIds] = useState<string[]>([])
  const [currentCameraId, setCurrentCameraId] = useState<string>()

  const {
    data,
    loading: isDeviceDataLoading,
    error: deviceQueryError,
  } = useDeviceFeedDetailsQuery({
    variables: { id: deviceId },
    skip: !deviceId,
  })

  const stream = useCameraFeed(
    isLiveStreamEnabled ? currentCameraId : null,
    onEvent
  )

  const nextCamera = () => {
    if (!availableCamerasIds.length) return
    const currentIndex = availableCamerasIds.indexOf(currentCameraId)
    const newIndex = (currentIndex + 1) % availableCamerasIds.length
    setCurrentCameraId(availableCamerasIds[newIndex])
  }

  const previousCamera = () => {
    if (!availableCamerasIds.length) return
    const currentIndex = availableCamerasIds.indexOf(currentCameraId)
    let newIndex = 0
    if (currentIndex === 0) {
      newIndex = availableCamerasIds.length - 1
    } else {
      newIndex = currentIndex - 1
    }
    setCurrentCameraId(availableCamerasIds[newIndex])
  }

  useEffect(() => {
    if (data) {
      let cameras: string[] = []

      if (
        data.device?.type === DeviceType.Door ||
        data.device?.type === DeviceType.Alarm
      ) {
        cameras =
          data.device?.tailgateConns?.edges
            .map((conn) =>
              conn.node.cameras?.edges.map((camera) => camera.node.id)
            )
            .reduce((result, current) => result.concat(current), []) || []
      }

      if (data.device?.type === DeviceType.Camera) {
        cameras = [data.device?.id]
      }

      setAvailableCamerasIds(cameras)
    }
  }, [data])

  useEffect(() => {
    if (availableCamerasIds.length > 0) {
      setCurrentCameraId(availableCamerasIds[0])
    }
  }, [availableCamerasIds])

  const errorMessage = deviceQueryError
    ? IncidentPlayerError.ERROR_FETCHING_STREAM
    : stream.errorMessage

  return {
    isDeviceDataLoading: isLiveStreamEnabled && isDeviceDataLoading,
    stream: {
      ...stream,
      isLoading: isLiveStreamEnabled ? stream.isLoading : false,
    },
    nextCamera,
    previousCamera,
    hasCamera: availableCamerasIds?.length > 0,
    availableCamerasIds,
    errorMessage,
  }
}
